<template>
  <div class="step--registration">
    <b-row>
      <b-col
        v-for="product in availableProducts"
        :key="`product-${product.id}`"
        :cols="productWidth"
        class="flex-grow-1 pb-4"
      >
        <product
          v-model="products[product.id]"
          v-bind="product"
          class="h-100"
        />
      </b-col>
    </b-row>
    <b-btn
      variant="primary"
      class="mt-5"
      :disabled="!canRegister"
      @click="submitProducts"
    >
      <b-spinner
        v-if="savingStep"
        class="mr-2"
        small
      />
      {{ buttonText }}
    </b-btn>
  </div>
</template>
<script>
import stepMixin from '@/mixins/step';
import Product from './registration/Product.vue';

export default {
  components: { Product },
  mixins: [stepMixin],
  data: () => ({
    products: {},
  }),
  computed: {
    availableProducts() {
      return this.context.products.filter((p) => p.state.status !== 'not_required');
    },
    buttonText() {
      return this.productsNotRequired.length && !this.productsRequired.length
        ? 'Submit'
        : 'Register';
    },
    canRegister() {
      return !this.savingStep
        && this.availableProducts.length
          === (
            this.productsRequired.length
            + this.productsNotRequired.length
            + this.productsCompleted.length
            + this.productsInMoratorium.length
          );
    },
    productsInMoratorium() {
      return Object.keys(this.products)
        .filter((p) => this.products[p].required
          && this.context.products.find((cp) => (cp.id === p)
          && (cp.state.moratorium && !cp.state.moratorium.over)));
    },
    productsRequired() {
      return Object.keys(this.products)
        .filter((p) => this.products[p].required
          && this.context.products.find((cp) => (cp.id === p)
            && (!cp.state.moratorium || cp.state.moratorium.over)
            && !cp.state.completed_at))
        .map((p) => this.context.products.find((cp) => cp.id === p));
    },
    productsNotRequired() {
      return Object.keys(this.products)
        .filter((p) => !this.products[p].required)
        .map((p) => this.context.products.find((cp) => cp.id === p));
    },
    productsCompleted() {
      return this.context.products.filter((p) => p.state.status === 'completed');
    },
    productWidth() {
      return Math.max(6, (12 / this.context.products.length));
    },
  },
  watch: {
    products: {
      deep: true,
      handler() {
        this.$emit('data-change');
      },
    },
  },
  methods: {
    async submitProducts() {
      try {
        const products = this.productsNotRequired.map((p) => ({
          product: p.id,
          required: false,
          policy: null,
        }));

        await Promise.all(this.productsRequired.map(async (f) => {
          const result = await this.$complianceForm.submit(`product-form-${f.id}`);
          products.push({
            product: f.id,
            required: true,
            policy: result.policy.id,
            price: result.policy.price,
            direct_debit_required: result.direct_debit_required,
          });
        }));

        const ddRequired = products.some((p) => p.direct_debit_required);
        const directDebitDetails = ddRequired
          ? await this.$compliancePrompt.prompt({
            title: 'Direct Debit Information',
            formModel: this.context.questions,
            size: 'lg',
          })
          : null;

        if (!ddRequired || directDebitDetails) {
          this.$emit(
            'submit',
            {
              products,
              direct_debit_details: directDebitDetails,
            },
          );
        }
      } catch (_) { /** */ }
    },
  },
};
</script>
