<template>
  <div class="step--default">
    <div class="mb-5">
      <compliance-form-wrapper
        v-for="(model, key) in forms"
        :id="`${step.step_key}-step-form-${key}`"
        :key="`step-form-${key}`"
        :form-model="model"
        :field-errors.sync="fieldErrorsMutable[key]"
        :show-submit="false"
        :disabled="state.is_locked || savingStep"
        :section-props="{
          'header-bg-variant': 'dark',
          'header-text-variant': 'white',
        }"
        :actions="formActions"
        @data-change="$emit('data-change', $event)"
      />
    </div>
    <b-btn
      v-if="!state.is_locked"
      variant="primary"
      :disabled="savingStep"
      @click="submitForms"
    >
      <b-spinner
        v-if="savingStep"
        class="mr-2"
        small
      />
      Submit
    </b-btn>
  </div>
</template>
<script>
import stepMixin from '@/mixins/step';

export default {
  mixins: [stepMixin],
};
</script>
