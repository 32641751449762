import lookupRepository from '@/api/repositories/sale/lookup';

export default async (field, formData, formModel, setField, done) => {
  const getId = (f) => f.id || f.key;
  const postcode = formData[getId(field)];
  try {
    const { data } = await lookupRepository.lookupAddress(postcode);
    formModel.fields.forEach((f) => {
      const value = data[getId(f)];
      if (value) setField(f, value, { wasProgrammatic: true });
      f.show = true;
    });
  } catch {
    formModel.fields.forEach((f) => {
      f.show = true;
    });
  }
  done();
};
