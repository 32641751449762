<template>
  <div class="step--demands-and-needs">
    <pdf-viewer
      :base64="context.demands_and_needs.document"
      :signature-areas="context.demands_and_needs.state === 'draft'
        ? context.demands_and_needs.signatures
        : []"
      :signatures="signaturesComputed"
      can-sign
      @signed="setSignature"
      @signature-removed="$delete(signatures, $event)"
    />
    <div
      v-if="hasError"
      class="invalid-feedback d-block"
    >
      Please ensure all signatures have been completed before proceeding
    </div>
    <b-btn
      v-if="!state.is_locked"
      variant="primary"
      class="mt-5"
      :disabled="savingStep"
      @click="saveDnd"
    >
      <b-spinner
        v-if="savingStep"
        class="mr-2"
        small
      />
      Submit
    </b-btn>
  </div>
</template>
<script>
// eslint-disable-next-line
import PdfViewer from '@itccompliance/compliance-pdf-viewer';
import stepMixin from '@/mixins/step';

export default {
  components: { PdfViewer },
  mixins: [stepMixin],
  data: () => ({
    signatures: {},
    hasError: false,
  }),
  computed: {
    signaturesComputed() {
      return Object.keys(this.signatures).map((k) => ({
        id: k,
        ...this.signatures[k],
      }));
    },
  },
  methods: {
    setSignature(data) {
      this.hasError = false;
      const { id, image, name } = data;
      this.$set(this.signatures, id, { image, name });
      this.$emit('data-change');
    },
    saveDnd() {
      this.hasError = false;
      const { context, signatures } = this;

      context.demands_and_needs.signatures.forEach((s) => {
        if (!signatures[s.id]) this.hasError = true;
      });

      if (!this.hasError) this.$emit('submit', { signatures });
    },
  },
};
</script>
