<template>
  <div class="sales-list">
    <b-form-group label="Search: ">
      <b-input-group>
        <b-form-input
          v-model="searchTerm"
          type="text"
          placeholder="Enter search term"
          debounce="500"
        />
        <b-input-group-append>
          <b-button
            variant="outline-dark"
            :disabled="!searchTerm"
            @click="searchTerm = null"
            v-text="'Clear'"
          />
        </b-input-group-append>
      </b-input-group>
      <b-form-text>
        Search by forename, surname, email, telephone number, VRM, VIN or Sale ID
      </b-form-text>
    </b-form-group>
    <b-table
      :items="sales ? sales.data : []"
      :fields="['customer', 'id', 'approach', 'current_step']"
      :busy="isLoading('pages')"
      head-variant="dark"
      empty-text="No sales found"
      show-empty
      hover
      small
      @row-clicked="openSale"
    >
      <template #empty="scope">
        <div
          class="text-center"
        >
          <b-spinner
            v-if="isLoading('pages')"
            label="Loading..."
            small
          />
          <i
            v-else
            v-text="scope.emptyText"
          />
        </div>
      </template>
      <template #cell(customer)="data">
        {{ customerName(data.item.customer) }}
      </template>
      <template #cell(id)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        {{ capitalise(data.value.replace(/[\-\_]/g, ' ')) }}
      </template>
    </b-table>
    <b-pagination
      v-if="sales && sales.meta.last_page > 1"
      v-model="currentPage"
      :number-of-pages="sales.meta.last_page"
      :per-page="sales.meta.per_page"
      :total-rows="sales.meta.total"
      align="center"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { loadingMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';

const { capitalise } = utils.string;

export default {
  mixins: [loadingMixin],
  data: () => ({
    sales: null,
    currentPage: 1,
    searchTerm: null,
  }),
  computed: {
    ...mapGetters('sale', ['saleCreateForm']),
    saleApproachForm() {
      return {
        ...this.saleCreateForm,
        fields: this.saleCreateForm.fields.map((f) => ({
          ...f,
          display_type: 'create-sale-radio',
        })).filter((f) => f.key === 'approach'),
      };
    },
  },
  watch: {
    currentPage: {
      immediate: true,
      handler(page) {
        this.doFetchSales(page);
      },
    },
    searchTerm() {
      if (this.currentPage > 1) {
        this.currentPage = 1;
      } else {
        this.doFetchSales();
      }
    },
  },
  mounted() {
    this.fetchSaleCreateForm();
  },
  methods: {
    ...mapActions('sale', ['fetchSales', 'fetchSaleCreateForm']),
    capitalise,
    async doFetchSales(pageNumber = 1) {
      this.setLoading('pages');
      const { searchTerm } = this;
      this.sales = await this.fetchSales({ pageNumber, searchTerm });
      this.unsetLoading('pages');
    },
    customerName(data) {
      return data && data.name && data.name.forename
        ? Object.values(data.name).join(' ')
        : '-';
    },
    async openSale(sale) {
      const approach = sale.current_step !== 'completion'
        ? await this.$compliancePrompt.prompt({
          title: 'Please select how the sale will be undertaken',
          formModel: this.saleApproachForm,
        })
        : undefined;
      if (approach || sale.current_step === 'completion') {
        this.$router.push({ name: 'View Sale', params: { saleId: sale.id, approach } });
      }
    },
  },
};
</script>
