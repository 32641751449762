var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-view h-100" },
    [
      _vm.isLoading("sale")
        ? _c(
            "div",
            {
              staticClass:
                "h-100 d-flex align-items-center justify-content-around"
            },
            [_c("b-spinner", { attrs: { label: "Loading..." } })],
            1
          )
        : [
            _c("div", { staticClass: "clearfix pb-2" }, [
              _c(
                "a",
                {
                  staticClass: "float-right btn btn-primary",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showSidebar("documents")
                    }
                  }
                },
                [
                  _c("b-icon-file-earmark-richtext"),
                  _vm._v(" Sale Documents ")
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "p-3 text-md-center bg-primary text-white" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { sm: "" } }, [
                      _vm._v(" Sale id: "),
                      _c("strong", {
                        domProps: { textContent: _vm._s(_vm.sale.id) }
                      })
                    ]),
                    _c("b-col", { attrs: { sm: "" } }, [
                      _vm._v(" Approach: "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("capitalise")(
                              _vm.sale.approach.replace(/-/g, " ")
                            )
                          )
                        )
                      ])
                    ]),
                    _c("b-col", { attrs: { sm: "" } }, [
                      _vm._v(" Customer name: "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            Object.values(_vm.sale.customer.name).some(function(
                              v
                            ) {
                              return v
                            })
                              ? Object.values(_vm.sale.customer.name).join(" ")
                              : "-"
                          )
                        )
                      ])
                    ]),
                    _c("b-col", { attrs: { sm: "" } }, [
                      _vm._v(" Customer type: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("capitalise")(_vm.sale.type)))
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.steps, function(step, key) {
              return _c(
                "b-card",
                {
                  key: key + "-step",
                  attrs: { "border-variant": "dark", "no-body": "" }
                },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-0",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value:
                                key !== _vm.currentStep ? key + "-step" : null,
                              expression:
                                "key !== currentStep ? `${key}-step` : null"
                            }
                          ],
                          staticClass: "p-2",
                          attrs: {
                            block: "",
                            variant:
                              key === _vm.currentStep ? "primary" : "dark",
                            disabled:
                              step.state.is_locked && !step.state.has_started
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(step.label) + " "),
                          _c(
                            step.state.has_completed
                              ? "BIconCheckCircle"
                              : step.state.is_locked
                              ? "BIconLock"
                              : "BIconPencil",
                            { tag: "component", staticClass: "right-2" }
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: key + "-step",
                        visible: _vm.sale.current_step === key,
                        accordion: "step-list",
                        role: "tabpanel"
                      },
                      on: {
                        show: function($event) {
                          return _vm.loadStep(key)
                        },
                        hidden: function($event) {
                          return _vm.$set(_vm.visibleSteps, key, false)
                        }
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _vm.stepForms[key]
                            ? _c(
                                _vm.stepComponents[
                                  _vm.$options.filters.camel(key) + "Step"
                                ] || _vm.stepComponents["defaultStep"],
                                _vm._b(
                                  {
                                    tag: "component",
                                    class: {
                                      invisible: _vm.isLoading("step-" + key)
                                    },
                                    attrs: {
                                      id: "step-form-" + key,
                                      "field-errors": _vm.fieldErrors,
                                      "prefill-data": _vm.stepData[key],
                                      visible: _vm.visibleSteps[key]
                                    },
                                    on: {
                                      "update:fieldErrors": function($event) {
                                        _vm.fieldErrors = $event
                                      },
                                      "update:field-errors": function($event) {
                                        _vm.fieldErrors = $event
                                      },
                                      submit: function($event) {
                                        return _vm.doSaveStep({
                                          key: key,
                                          data: $event
                                        })
                                      },
                                      "data-change": function($event) {
                                        return _vm.$set(
                                          _vm.stepData,
                                          key,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  "component",
                                  Object.assign({}, _vm.stepForms[key], {
                                    savingStep: _vm.savingStep
                                  }),
                                  false
                                )
                              )
                            : _c(
                                "div",
                                { staticClass: "py-5 text-center" },
                                [
                                  _c("b-spinner", {
                                    attrs: { label: "Loading..." }
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _c("sidebar", {
              attrs: {
                visible: _vm.sidebarVisible,
                title: "documents",
                position: "right",
                items: _vm.sale.documents,
                "loading-items": _vm.isLoading("sidebar")
              },
              on: {
                "update:visible": function($event) {
                  _vm.sidebarVisible = $event
                }
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }