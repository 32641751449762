var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step--completion" },
    [
      _c("h1", [_vm._v("Sale Complete")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("h3", [_vm._v("Documents")]),
              _c("p", [
                _vm._v("Please find the documents relating to this sale below")
              ]),
              _c("b-table", {
                attrs: {
                  items: _vm.context.documents,
                  fields: ["label", "url"],
                  "show-empty": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(url)",
                    fn: function(data) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: data.item.url,
                              download: "",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Click to download")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("h3", [_vm._v("Registered Policies")]),
              _c("p", [
                _vm._v(
                  "Please find the registered products/policies for this sale below"
                )
              ]),
              _c("b-table", {
                attrs: {
                  items: _vm.context.policies,
                  fields: [
                    "supplier",
                    { key: "product.overview.name", label: "Name" },
                    { key: "details.description", label: "Level" },
                    { key: "details.term", label: "Term" },
                    { key: "price.formatted", label: "Price" },
                    { key: "payment_method", label: "Payment Method" }
                  ],
                  "show-empty": "",
                  "empty-text": "No products registered"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }