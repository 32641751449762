var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step--registration" },
    [
      _c(
        "b-row",
        _vm._l(_vm.availableProducts, function(product) {
          return _c(
            "b-col",
            {
              key: "product-" + product.id,
              staticClass: "flex-grow-1 pb-4",
              attrs: { cols: _vm.productWidth }
            },
            [
              _c(
                "product",
                _vm._b(
                  {
                    staticClass: "h-100",
                    model: {
                      value: _vm.products[product.id],
                      callback: function($$v) {
                        _vm.$set(_vm.products, product.id, $$v)
                      },
                      expression: "products[product.id]"
                    }
                  },
                  "product",
                  product,
                  false
                )
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "b-btn",
        {
          staticClass: "mt-5",
          attrs: { variant: "primary", disabled: !_vm.canRegister },
          on: { click: _vm.submitProducts }
        },
        [
          _vm.savingStep
            ? _c("b-spinner", { staticClass: "mr-2", attrs: { small: "" } })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.buttonText) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }