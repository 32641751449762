var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                _vm._b(
                  { attrs: { header: "Create a new sale" } },
                  "b-card",
                  _vm.cardProps,
                  false
                ),
                [
                  _vm.saleCreateForm
                    ? _c("compliance-form-wrapper", {
                        attrs: {
                          "form-model": _vm.saleCreateFormComputed,
                          "field-errors": _vm.fieldErrors
                        },
                        on: {
                          "update:fieldErrors": function($event) {
                            _vm.fieldErrors = $event
                          },
                          "update:field-errors": function($event) {
                            _vm.fieldErrors = $event
                          },
                          submit: _vm.doCreateSale
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }