var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    _vm._b({ attrs: { "no-body": "" } }, "b-card", _vm.cardProps, false),
    [
      _c(
        "b-card-header",
        _vm._b(
          { staticClass: "pb-0 h6" },
          "b-card-header",
          _vm.cardProps,
          false
        ),
        [
          _c("h3", {
            staticClass: "float-left",
            domProps: { textContent: _vm._s(_vm.overview.name) }
          }),
          !_vm.state.completed_at
            ? _c("div", { staticClass: "float-right form-inline" }, [
                _c(
                  "label",
                  [
                    _vm._v(" Required: "),
                    _c("b-form-checkbox", {
                      staticClass: "ml-2",
                      attrs: { switch: "", disabled: !_vm.policies.length },
                      model: {
                        value: _vm.productRequired,
                        callback: function($$v) {
                          _vm.productRequired = $$v
                        },
                        expression: "productRequired"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "b-card-body",
        [
          _vm.state.completed_at
            ? _c("b-alert", { attrs: { variant: "success", show: "" } }, [
                _c("p", [
                  _vm._v("This product has been successfully registered")
                ])
              ])
            : _vm.productRequired
            ? [
                _vm.inMoratorium || _vm.state.failed_at
                  ? _c(
                      "b-alert",
                      {
                        attrs: {
                          show: "",
                          variant:
                            !_vm.customerCanOverride || _vm.state.failed_at
                              ? "danger"
                              : "warning"
                        }
                      },
                      [
                        _vm.state.failed_at
                          ? _c("p", {
                              domProps: {
                                textContent: _vm._s(_vm.productError)
                              }
                            })
                          : [
                              _c("p", { staticClass: "lead" }, [
                                _vm._v(
                                  " This product is currently blocked by Moratorium. "
                                )
                              ]),
                              _c(
                                "p",
                                [
                                  _vm._v(
                                    " This product is available to conclude "
                                  ),
                                  !_vm.customerCanOverride
                                    ? [
                                        _vm._v(
                                          " at the specific unsolicited request of the customer on "
                                        ),
                                        _c("strong", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.overrideDate
                                            )
                                          }
                                        }),
                                        _vm._v(", or general conclusion ")
                                      ]
                                    : _vm._e(),
                                  _vm._v(" on "),
                                  _c("strong", {
                                    domProps: {
                                      textContent: _vm._s(_vm.moratoriumOver)
                                    }
                                  }),
                                  _vm._v(". ")
                                ],
                                2
                              ),
                              _vm.productRequired && _vm.customerCanOverride
                                ? [
                                    _c("p", [
                                      _vm._v(
                                        " You have elected to purchase " +
                                          _vm._s(_vm.overview.name) +
                                          " within the Financial Conduct Authority moratorium period of two clear days from receiving information contained within your Demands and Needs documentation. In so doing you confirm that you have made the specific unsolicited purchase approach without influence or coercion. "
                                      )
                                    ]),
                                    _c("compliance-form-wrapper", {
                                      attrs: {
                                        id: "product-" + _vm.id + "-override",
                                        "form-model": _vm.overrideForm
                                      },
                                      on: {
                                        submit: function($event) {
                                          return _vm.overrideProductMoratorium(
                                            _vm.id
                                          )
                                        }
                                      }
                                    })
                                  ]
                                : _vm._e()
                            ]
                      ],
                      2
                    )
                  : _vm._e(),
                !_vm.inMoratorium
                  ? _c(
                      "compliance-form-wrapper",
                      _vm._b(
                        {
                          attrs: {
                            id: "product-form-" + _vm.id,
                            "show-submit": false
                          }
                        },
                        "compliance-form-wrapper",
                        { formModel: _vm.formModel },
                        false
                      )
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          !_vm.policies.length
            ? _c(
                "b-alert",
                { staticClass: "mb-0", attrs: { show: "", variant: "danger" } },
                [_vm._v(" There are no policies available for this product ")]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }