var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    _vm._b(
      {
        attrs: {
          header: _vm.product.overview.name,
          "header-class": "h4",
          "no-body": ""
        }
      },
      "b-card",
      _vm.cardProps,
      false
    ),
    [
      _vm.product.compliance.video
        ? _c(
            "div",
            {
              staticClass:
                "embed-responsive embed-responsive-16by9 card-img-top"
            },
            [
              _c("iframe", {
                staticClass: "embed-responsive-item",
                attrs: { src: _vm.product.compliance.video, frameborder: "0" }
              })
            ]
          )
        : _vm._e(),
      _c(
        "b-card-body",
        [
          _c("p", {
            staticClass: "lead",
            domProps: { textContent: _vm._s(_vm.product.overview.tagline) }
          }),
          _c("p", {
            domProps: { textContent: _vm._s(_vm.product.overview.description) }
          }),
          _c("h2", { staticClass: "h6" }, [_vm._v(" Available policies: ")]),
          _vm._l(_vm.product.policies, function(supplier, ix) {
            return _c(
              "b-list-group",
              { key: "policy-supplier-" + ix },
              [
                _vm._l(supplier, function(policy, policyIx) {
                  return [
                    policyIx === 0
                      ? _c(
                          "b-list-group-item",
                          {
                            key: "supplier-" + policy.id + "-Header",
                            attrs: { variant: "primary" }
                          },
                          [
                            _c("h4", {
                              staticClass: "h6 mb-0",
                              domProps: { textContent: _vm._s(policy.supplier) }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._l(policy.terms, function(term, termIx) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: "policy-" + policy.id + "-terms-" + termIx,
                          staticClass: "d-flex justify-content-between"
                        },
                        [
                          _c("strong", {
                            domProps: { textContent: _vm._s(policy.name) }
                          }),
                          _c("span", {
                            staticClass: "mx-1",
                            domProps: { textContent: _vm._s(term.label) }
                          }),
                          _c("span", {
                            staticClass: "mx-1",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$options.filters.currency(term.rrp)
                              )
                            }
                          })
                        ]
                      )
                    })
                  ]
                })
              ],
              2
            )
          })
        ],
        2
      ),
      _c("b-card-footer", [
        _c(
          "div",
          {
            staticClass: "px-0 text-left",
            class: {
              "btn btn-text": !_vm.disabled,
              "text-light": _vm.disabled
            },
            on: {
              click: function($event) {
                !_vm.disabled ? _vm.$emit("input", !_vm.value) : null
              }
            }
          },
          [
            _c(_vm.value ? "BIconCheckSquare" : "BIconSquare", {
              tag: "component",
              staticClass: "mr-3",
              attrs: { scale: "1.5" }
            }),
            _vm._v(" Select "),
            _c("strong", {
              domProps: { textContent: _vm._s(_vm.product.overview.name) }
            }),
            _vm._v(" for consideration ")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }