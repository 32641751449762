export default {
  fields: [
    {
      id: 'moratorium_override',
      label: 'I confirm that I have initiated the request for an earlier conclusion of the contract',
      display_type: 'checkbox',
      rules: ['confirm'],
    },
  ],
};
