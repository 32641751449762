var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      staticClass: "sale-sidebar",
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        "header-class": "text-capitalize",
        "bg-variant": "white",
        backdrop: "",
        right: "",
        shadow: ""
      },
      on: {
        change: function($event) {
          return _vm.$emit("update:visible", $event)
        }
      }
    },
    [
      _c(
        "b-list-group",
        { staticClass: "sale-sidebar__list p-3" },
        [
          !_vm.items.length && !_vm.loadingItems
            ? _c("b-list-group-item", { staticClass: "border-0" }, [
                _c("div", {
                  class: [
                    "sale-sidebar__list__item",
                    "sale-sidebar__list__item--empty",
                    "text-center",
                    "font-italic",
                    "text-muted"
                  ],
                  domProps: {
                    textContent: _vm._s("No " + _vm.title + " found")
                  }
                })
              ])
            : _vm._e(),
          _vm._l(_vm.items, function(item, ix) {
            return _c(
              "b-list-group-item",
              {
                key: "sidebar-item-" + ix,
                class: [
                  "py-2",
                  "px-0",
                  "border-left-0",
                  "border-right-0",
                  "border-top-0",
                  { "border-bottom-0": ix === _vm.items.length - 1 }
                ]
              },
              [
                _c("sidebar-" + _vm.title, {
                  tag: "component",
                  staticClass: "sale-sidebar__list__item",
                  attrs: { item: item }
                })
              ],
              1
            )
          }),
          _c(
            "b-list-group-item",
            { ref: "loadMore", staticClass: "border-0" },
            [
              _c(
                "div",
                { staticClass: "sale-sidebar__list__item--more text-center" },
                [
                  _vm.loadingItems
                    ? _c("b-icon-three-dots", {
                        attrs: { animation: "cylon", variant: "black-50" }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }