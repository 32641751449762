var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step--default" },
    [
      _c(
        "div",
        { staticClass: "mb-5" },
        _vm._l(_vm.forms, function(model, key) {
          return _c("compliance-form-wrapper", {
            key: "step-form-" + key,
            attrs: {
              id: _vm.step.step_key + "-step-form-" + key,
              "form-model": model,
              "field-errors": _vm.fieldErrorsMutable[key],
              "show-submit": false,
              disabled: _vm.state.is_locked || _vm.savingStep,
              "section-props": {
                "header-bg-variant": "dark",
                "header-text-variant": "white"
              },
              actions: _vm.formActions
            },
            on: {
              "update:fieldErrors": function($event) {
                return _vm.$set(_vm.fieldErrorsMutable, key, $event)
              },
              "update:field-errors": function($event) {
                return _vm.$set(_vm.fieldErrorsMutable, key, $event)
              },
              "data-change": function($event) {
                return _vm.$emit("data-change", $event)
              }
            }
          })
        }),
        1
      ),
      !_vm.state.is_locked
        ? _c(
            "b-btn",
            {
              attrs: { variant: "primary", disabled: _vm.savingStep },
              on: { click: _vm.submitForms }
            },
            [
              _vm.savingStep
                ? _c("b-spinner", { staticClass: "mr-2", attrs: { small: "" } })
                : _vm._e(),
              _vm._v(" Submit ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }