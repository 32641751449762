import completionStep from './Completion.vue';
import defaultStep from './Default.vue';
import demandsAndNeedsStep from './DemandsAndNeeds.vue';
import privacyPolicyStep from './PrivacyPolicy.vue';
import productSelectStep from './ProductSelect.vue';
import qualificationStep from './Qualification.vue';
import registrationStep from './Registration.vue';

export default {
  completionStep,
  defaultStep,
  demandsAndNeedsStep,
  privacyPolicyStep,
  productSelectStep,
  qualificationStep,
  registrationStep,
};
