<template>
  <div class="step--privacy-policy">
    <p>
      We will only use your data in line with our data privacy policy.
      <router-link
        :to="{ name: 'Privacy Policy' }"
        target="_blank"
      >
        Click Here
      </router-link>
      to see a full copy. You will have a copy emailed to you during this process.
    </p>
    <compliance-form-wrapper
      :id="`${step.step_key}-step-form`"
      :key="`step-form-${step.step_key}`"
      :form-model="step.questions"
      :field-errors.sync="fieldErrors"
      :disabled="state.is_locked"
      :section-props="{
        'header-bg-variant': 'dark',
        'header-text-variant': 'white',
      }"
      :show-submitting="savingStep"
      @submit="$emit('submit', { questions: $event })"
    />
  </div>
</template>
<script>
import stepMixin from '@/mixins/step';

export default {
  mixins: [stepMixin],
};
</script>
