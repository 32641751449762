<template>
  <div class="step--completion">
    <h1>Sale Complete</h1>
    <b-row>
      <b-col>
        <h3>Documents</h3>
        <p>Please find the documents relating to this sale below</p>
        <b-table
          :items="context.documents"
          :fields="['label', 'url']"
          show-empty
        >
          <template #cell(url)="data">
            <a
              :href="data.item.url"
              download
              target="_blank"
            >Click to download</a>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Registered Policies</h3>
        <p>Please find the registered products/policies for this sale below</p>
        <b-table
          :items="context.policies"
          :fields="[
            'supplier',
            { key: 'product.overview.name', label: 'Name' },
            { key: 'details.description', label: 'Level' },
            { key: 'details.term', label: 'Term' },
            { key: 'price.formatted', label: 'Price' },
            { key: 'payment_method', label: 'Payment Method' },
          ]"
          show-empty
          empty-text="No products registered"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import stepMixin from '@/mixins/step';

export default {
  mixins: [stepMixin],
};
</script>
