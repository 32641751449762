<template>
  <b-sidebar
    :visible="visible"
    class="sale-sidebar"
    :title="title"
    header-class="text-capitalize"
    bg-variant="white"
    backdrop
    right
    shadow
    @change="$emit('update:visible', $event)"
  >
    <b-list-group class="sale-sidebar__list p-3">
      <b-list-group-item
        v-if="!items.length && !loadingItems"
        class="border-0"
      >
        <div
          :class="[
            'sale-sidebar__list__item',
            'sale-sidebar__list__item--empty',
            'text-center',
            'font-italic',
            'text-muted'
          ]"
          v-text="`No ${title} found`"
        />
      </b-list-group-item>
      <b-list-group-item
        v-for="(item, ix) in items"
        :key="`sidebar-item-${ix}`"
        :class="[
          'py-2',
          'px-0',
          'border-left-0',
          'border-right-0',
          'border-top-0',
          { 'border-bottom-0': ix === items.length - 1 }
        ]"
      >
        <component
          :is="`sidebar-${title}`"
          :item="item"
          class="sale-sidebar__list__item"
        />
      </b-list-group-item>
      <b-list-group-item
        ref="loadMore"
        class="border-0"
      >
        <div
          class="sale-sidebar__list__item--more text-center"
        >
          <b-icon-three-dots
            v-if="loadingItems"
            animation="cylon"
            variant="black-50"
          />
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>
<script>
import { BIconThreeDots } from 'bootstrap-vue';
import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import * as sideBarComponents from './sidebar';

const { isInViewport } = utils.dom;

export default {
  components: { ...sideBarComponents, BIconThreeDots },
  props: {
    title: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'right',
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    loadingItems: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.addEventListeners();
      } else {
        this.removeEventListeners();
      }
    },
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  methods: {
    maybeLoadMore() {
      const { loadMore } = this.$refs;
      if (isInViewport(loadMore) && !this.loadingItems) this.$emit('load-more');
    },
    addEventListeners() {
      this.$el.querySelector('.b-sidebar-body').addEventListener(
        'scroll',
        this.maybeLoadMore,
      );
    },
    removeEventListeners() {
      this.$el.querySelector('.b-sidebar-body').removeEventListener(
        'scroll',
        this.maybeLoadMore,
      );
    },
  },
};
</script>
