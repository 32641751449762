var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step--privacy-policy" },
    [
      _c(
        "p",
        [
          _vm._v(
            " We will only use your data in line with our data privacy policy. "
          ),
          _c(
            "router-link",
            { attrs: { to: { name: "Privacy Policy" }, target: "_blank" } },
            [_vm._v(" Click Here ")]
          ),
          _vm._v(
            " to see a full copy. You will have a copy emailed to you during this process. "
          )
        ],
        1
      ),
      _c("compliance-form-wrapper", {
        key: "step-form-" + _vm.step.step_key,
        attrs: {
          id: _vm.step.step_key + "-step-form",
          "form-model": _vm.step.questions,
          "field-errors": _vm.fieldErrors,
          disabled: _vm.state.is_locked,
          "section-props": {
            "header-bg-variant": "dark",
            "header-text-variant": "white"
          },
          "show-submitting": _vm.savingStep
        },
        on: {
          "update:fieldErrors": function($event) {
            _vm.fieldErrors = $event
          },
          "update:field-errors": function($event) {
            _vm.fieldErrors = $event
          },
          submit: function($event) {
            return _vm.$emit("submit", { questions: $event })
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }