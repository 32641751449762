<template>
  <div class="step--product-select">
    <p v-text="stepDescription" />
    <b-row v-if="!noProductsRequired">
      <b-col
        v-for="product in context.suggested_products"
        :key="`product-${product.id}`"
        :cols="productWidth"
        class="flex-grow-1 pb-4"
      >
        <product
          v-bind="{ product }"
          :value="!!products.find((p) => p === product.id)"
          :disabled="state.has_completed"
          class="h-100"
          @input="onInput(product.id, $event)"
        />
      </b-col>
    </b-row>
    <b-alert
      variant="warning"
      :show="noProductsRequired"
    >
      You have opted to proceed with no products
    </b-alert>
    <b-form-group :state="error ? false : null">
      <b-form-checkbox
        v-model="noProductsRequired"
        class="mb-4"
        :disabled="state.has_completed"
      >
        Mark sale as 'No Products Sold'
        <b-form-invalid-feedback
          v-if="error && !noProductsRequired"
          :state="false"
        >
          Please
          <span
            v-if="context.suggested_products.length"
            v-text="'select at least one product or '"
          />confirm that no products are required
        </b-form-invalid-feedback>
      </b-form-checkbox>
    </b-form-group>
    <b-btn
      v-if="!state.is_locked"
      variant="primary"
      :disabled="savingStep"
      @click="doSubmit"
    >
      <b-spinner
        v-if="savingStep"
        class="mr-2"
        small
      />
      Submit
    </b-btn>
  </div>
</template>
<script>
import stepMixin from '@/mixins/step';
import Product from './productSelect/Product.vue';

export default {
  components: { Product },
  mixins: [stepMixin],
  data: () => ({
    products: [],
    noProductsRequired: false,
    error: false,
  }),
  computed: {
    productWidth() {
      return Math.max(6, (12 / this.context.suggested_products.length));
    },
    stepDescription() {
      return this.context.suggested_products.length
        ? 'Based on the responses provided, we recommend the following products:'
        : 'Based on the qualification responses, there are no eligible products available.';
    },
  },
  watch: {
    'context.suggested_products': {
      immediate: true,
      handler(products) {
        this.products = products
          .filter((p) => p.state.is_selected)
          .map((p) => p.id);
      },
    },
    products(products) {
      this.error = this.noProductsRequired || !products.length;
      this.$emit('data-change');
    },
    noProductsRequired(required) {
      if (required) {
        this.error = false;
        this.products = [];
      } else this.error = !this.products.length;
    },
  },
  methods: {
    onInput(productId, selected) {
      if (selected) {
        this.products = Array.from(new Set([
          ...this.products,
          productId,
        ]));
      } else {
        this.products = this.products.filter((p) => p !== productId);
      }
    },
    doSubmit() {
      const { noProductsRequired, products } = this;
      if (noProductsRequired || products.length) {
        this.$emit('submit', {
          products: noProductsRequired ? [] : products,
          no_products_required: noProductsRequired,
        });
      } else {
        this.error = true;
      }
    },
  },
};
</script>
