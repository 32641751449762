var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sales-list" },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Search: " } },
        [
          _c(
            "b-input-group",
            [
              _c("b-form-input", {
                attrs: {
                  type: "text",
                  placeholder: "Enter search term",
                  debounce: "500"
                },
                model: {
                  value: _vm.searchTerm,
                  callback: function($$v) {
                    _vm.searchTerm = $$v
                  },
                  expression: "searchTerm"
                }
              }),
              _c(
                "b-input-group-append",
                [
                  _c("b-button", {
                    attrs: {
                      variant: "outline-dark",
                      disabled: !_vm.searchTerm
                    },
                    domProps: { textContent: _vm._s("Clear") },
                    on: {
                      click: function($event) {
                        _vm.searchTerm = null
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-form-text", [
            _vm._v(
              " Search by forename, surname, email, telephone number, VRM, VIN or Sale ID "
            )
          ])
        ],
        1
      ),
      _c("b-table", {
        attrs: {
          items: _vm.sales ? _vm.sales.data : [],
          fields: ["customer", "id", "approach", "current_step"],
          busy: _vm.isLoading("pages"),
          "head-variant": "dark",
          "empty-text": "No sales found",
          "show-empty": "",
          hover: "",
          small: ""
        },
        on: { "row-clicked": _vm.openSale },
        scopedSlots: _vm._u([
          {
            key: "empty",
            fn: function(scope) {
              return [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm.isLoading("pages")
                      ? _c("b-spinner", {
                          attrs: { label: "Loading...", small: "" }
                        })
                      : _c("i", {
                          domProps: { textContent: _vm._s(scope.emptyText) }
                        })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(customer)",
            fn: function(data) {
              return [
                _vm._v(" " + _vm._s(_vm.customerName(data.item.customer)) + " ")
              ]
            }
          },
          {
            key: "cell(id)",
            fn: function(data) {
              return [_vm._v(" " + _vm._s(data.value) + " ")]
            }
          },
          {
            key: "cell()",
            fn: function(data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.capitalise(data.value.replace(/[\-\_]/g, " "))) +
                    " "
                )
              ]
            }
          }
        ])
      }),
      _vm.sales && _vm.sales.meta.last_page > 1
        ? _c("b-pagination", {
            attrs: {
              "number-of-pages": _vm.sales.meta.last_page,
              "per-page": _vm.sales.meta.per_page,
              "total-rows": _vm.sales.meta.total,
              align: "center"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }