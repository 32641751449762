import Repository from '../../Repository';

export class LookupRepository extends Repository {
  async lookupAddress(postcode) {
    const { data } = await this.client.get('lookups/address', { params: { postcode } });
    return data;
  }

  async lookupVehicle(vrn) {
    const { data } = await this.client.get('lookups/vehicle', { params: { vrn } });
    return data;
  }
}

export default new LookupRepository();
