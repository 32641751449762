var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step--product-select" },
    [
      _c("p", { domProps: { textContent: _vm._s(_vm.stepDescription) } }),
      !_vm.noProductsRequired
        ? _c(
            "b-row",
            _vm._l(_vm.context.suggested_products, function(product) {
              return _c(
                "b-col",
                {
                  key: "product-" + product.id,
                  staticClass: "flex-grow-1 pb-4",
                  attrs: { cols: _vm.productWidth }
                },
                [
                  _c(
                    "product",
                    _vm._b(
                      {
                        staticClass: "h-100",
                        attrs: {
                          value: !!_vm.products.find(function(p) {
                            return p === product.id
                          }),
                          disabled: _vm.state.has_completed
                        },
                        on: {
                          input: function($event) {
                            return _vm.onInput(product.id, $event)
                          }
                        }
                      },
                      "product",
                      { product: product },
                      false
                    )
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "b-alert",
        { attrs: { variant: "warning", show: _vm.noProductsRequired } },
        [_vm._v(" You have opted to proceed with no products ")]
      ),
      _c(
        "b-form-group",
        { attrs: { state: _vm.error ? false : null } },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "mb-4",
              attrs: { disabled: _vm.state.has_completed },
              model: {
                value: _vm.noProductsRequired,
                callback: function($$v) {
                  _vm.noProductsRequired = $$v
                },
                expression: "noProductsRequired"
              }
            },
            [
              _vm._v(" Mark sale as 'No Products Sold' "),
              _vm.error && !_vm.noProductsRequired
                ? _c("b-form-invalid-feedback", { attrs: { state: false } }, [
                    _vm._v(" Please "),
                    _vm.context.suggested_products.length
                      ? _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              "select at least one product or "
                            )
                          }
                        })
                      : _vm._e(),
                    _vm._v("confirm that no products are required ")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      !_vm.state.is_locked
        ? _c(
            "b-btn",
            {
              attrs: { variant: "primary", disabled: _vm.savingStep },
              on: { click: _vm.doSubmit }
            },
            [
              _vm.savingStep
                ? _c("b-spinner", { staticClass: "mr-2", attrs: { small: "" } })
                : _vm._e(),
              _vm._v(" Submit ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }