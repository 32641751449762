<template>
  <b-card
    :header="product.overview.name"
    header-class="h4"
    v-bind="cardProps"
    no-body
  >
    <div
      v-if="product.compliance.video"
      class="embed-responsive embed-responsive-16by9 card-img-top"
    >
      <iframe
        :src="product.compliance.video"
        class="embed-responsive-item"
        frameborder="0"
      />
    </div>
    <b-card-body>
      <p
        class="lead"
        v-text="product.overview.tagline"
      />
      <p v-text="product.overview.description" />

      <h2 class="h6">
        Available policies:
      </h2>
      <b-list-group
        v-for="(supplier, ix) in product.policies"
        :key="`policy-supplier-${ix}`"
      >
        <template v-for="(policy, policyIx) in supplier">
          <b-list-group-item
            v-if="policyIx === 0"
            :key="`supplier-${policy.id}-Header`"
            variant="primary"
          >
            <h4
              class="h6 mb-0"
              v-text="policy.supplier"
            />
          </b-list-group-item>
          <b-list-group-item
            v-for="(term, termIx) in policy.terms"
            :key="`policy-${policy.id}-terms-${termIx}`"
            class="d-flex justify-content-between"
          >
            <strong v-text="policy.name" />
            <span
              class="mx-1"
              v-text="term.label"
            />
            <span
              class="mx-1"
              v-text="$options.filters.currency(term.rrp)"
            />
          </b-list-group-item>
        </template>
      </b-list-group>
    </b-card-body>
    <b-card-footer>
      <div
        class="px-0 text-left"
        :class="{ 'btn btn-text': !disabled, 'text-light': disabled }"
        @click="!disabled ? $emit('input', !value) : null"
      >
        <component
          :is="value ? 'BIconCheckSquare' : 'BIconSquare'"
          scale="1.5"
          class="mr-3"
        />
        Select <strong v-text="product.overview.name" /> for consideration
      </div>
    </b-card-footer>
  </b-card>
</template>
<script>
import { BIconCheckSquare, BIconSquare } from 'bootstrap-vue';

export default {
  components: { BIconCheckSquare, BIconSquare },
  props: {
    product: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
