<template>
  <a
    :href="item.url"
    download
  >
    <b-icon-download class="mr-2" />
    {{ item.label }}
  </a>
</template>
<script>
import { BIconDownload } from 'bootstrap-vue';

export default {
  components: {
    BIconDownload,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
