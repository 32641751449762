import isEqual from 'lodash.isequal';
import formActions from '@/actions';

export default {
  props: {
    context: {
      default: () => ({}),
    },
    dates: {
      type: Object,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
    prefillData: {
      type: Object,
      default: null,
    },
    savingStep: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Object,
      required: true,
    },
    fieldErrors: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    formActions,
    forms: {},
    fieldErrorsMutable: {},
  }),
  watch: {
    fieldErrors: {
      immediate: true,
      handler(errors) {
        if (errors) {
          Object.keys(errors).forEach((k) => {
            const [formKey, fieldId] = k.split('.');
            this.$set(this.fieldErrorsMutable[formKey], fieldId, errors[k]);
          });
        }
      },
    },
    fieldErrorsMutable: {
      deep: true,
      handler(errors) {
        this.$emit(
          'update:field-errors',
          Object.keys(errors).reduce((r, k) => {
            Object.keys(errors[k]).forEach((f) => {
              r[`${k}.${f}`] = errors[k][f];
            });
            return r;
          }, {}),
        );
      },
    },
    step: {
      immediate: true,
      handler(step, old) {
        if (!isEqual(step, old)) {
          this.getForms();
        }
      },
    },
  },
  methods: {
    getForms() {
      const forms = {};
      const { step, prefillData } = this;
      ['scripts', 'questions', 'declarations'].forEach((f) => {
        if (step[f] && step[f].fields && step[f].fields.length) {
          forms[f] = prefillData
            ? {
              ...step[f],
              fields: step[f].fields.map((field) => {
                if (prefillData[field.id] && field.value === null) {
                  field.value = prefillData[field.id];
                }
                return field;
              }),
            }
            : step[f];
        }
      });
      Object.keys(forms).forEach((k) => {
        this.$set(this.fieldErrorsMutable, k, {});
      });
      this.forms = forms;
    },
    async submitForms() {
      try {
        const data = {};
        await Promise.all(Object.keys(this.forms).map(async (f) => {
          const result = await this.$complianceForm.submit(`${this.step.step_key}-step-form-${f}`);
          data[f] = result;
        }));
        this.$emit('submit', data);
      } catch { /** */ }
    },
  },
};
