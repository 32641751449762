<template>
  <div class="sale-view h-100">
    <div
      v-if="isLoading('sale')"
      class="h-100 d-flex align-items-center justify-content-around"
    >
      <b-spinner label="Loading..." />
    </div>
    <template v-else>
      <div class="clearfix pb-2">
        <a
          class="float-right btn btn-primary"
          href="#"
          @click.prevent="showSidebar('documents')"
        >
          <b-icon-file-earmark-richtext />
          Sale Documents
        </a>
      </div>
      <div
        class="p-3 text-md-center bg-primary text-white"
      >
        <b-row>
          <b-col sm>
            Sale id: <strong v-text="sale.id" />
          </b-col>
          <b-col sm>
            Approach: <strong>{{ sale.approach.replace(/-/g, ' ') | capitalise }}</strong>
          </b-col>
          <b-col sm>
            Customer name: <strong>{{ Object.values(sale.customer.name).some((v) => v)
              ? Object.values(sale.customer.name).join(' ')
              : '-' }}</strong>
          </b-col>
          <b-col sm>
            Customer type: <strong>{{ sale.type | capitalise }}</strong>
          </b-col>
        </b-row>
      </div>
      <b-card
        v-for="(step, key) in steps"
        :key="`${key}-step`"
        border-variant="dark"
        no-body
      >
        <b-card-header
          header-tag="header"
          class="p-0"
          role="tab"
        >
          <b-button
            v-b-toggle="key !== currentStep ? `${key}-step` : null"
            class="p-2"
            block
            :variant="key === currentStep ? 'primary' : 'dark'"
            :disabled="step.state.is_locked && !step.state.has_started"
          >
            {{ step.label }}
            <component
              :is="step.state.has_completed
                ? 'BIconCheckCircle'
                : ( step.state.is_locked ? 'BIconLock' : 'BIconPencil' )"
              class="right-2"
            />
          </b-button>
        </b-card-header>
        <b-collapse
          :id="`${key}-step`"
          :visible="sale.current_step === key"
          accordion="step-list"
          role="tabpanel"
          @show="loadStep(key)"
          @hidden="$set(visibleSteps, key, false)"
        >
          <b-card-body>
            <component
              :is="stepComponents[`${$options.filters.camel(key)}Step`]
                || stepComponents['defaultStep']"
              v-if="stepForms[key]"
              :id="`step-form-${key}`"
              v-bind="{ ...stepForms[key], savingStep }"
              :field-errors.sync="fieldErrors"
              :class="{ invisible: isLoading(`step-${key}`) }"
              :prefill-data="stepData[key]"
              :visible="visibleSteps[key]"
              @submit="doSaveStep({ key, data: $event})"
              @data-change="$set(stepData, key, $event)"
            />
            <div
              v-else
              class="py-5 text-center"
            >
              <b-spinner label="Loading..." />
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
      <sidebar
        :visible.sync="sidebarVisible"
        title="documents"
        position="right"
        :items="sale.documents"
        :loading-items="isLoading('sidebar')"
      />
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  BIconCheckCircle, BIconPencil, BIconLock, BIconFileEarmarkRichtext,
} from 'bootstrap-vue';
import { loadingMixin } from '@itccompliance/compliance-vue-essentials-plugin';
import Sidebar from '@/components/sale/Sidebar.vue';
import stepComponents from '@/components/steps';

export default {
  components: {
    BIconCheckCircle,
    BIconFileEarmarkRichtext,
    BIconPencil,
    BIconLock,
    Sidebar,
  },
  mixins: [loadingMixin],
  data: () => ({
    fieldErrors: null,
    currentStep: null,
    savingStep: false,
    sidebarVisible: false,
    stepComponents,
    stepData: {},
    visibleSteps: {},
  }),
  computed: {
    ...mapGetters('sale', ['sale']),
    ...mapGetters('sale/steps', ['steps', 'stepForms']),
  },
  created() {
    this.loadSale(this.$route.params.saleId, this.$route.params.approach);
  },
  methods: {
    ...mapActions('sale', ['fetchSale', 'fetchSaleDocuments']),
    ...mapActions('sale/steps', ['fetchSteps', 'fetchStep', 'saveStep']),
    async doSaveStep(data) {
      this.savingStep = true;
      try {
        await this.saveStep(data);
        const newStep = Object.keys(this.steps).find((s) => !!this.steps[s].state.in_progress);
        if (newStep && this.currentStep !== newStep) {
          this.currentStep = newStep;
          this.$root.$emit('bv::open::collapse', `${newStep}-step`);
        }
        this.savingStep = false;
      } catch (e) {
        if (e.data) {
          this.fieldErrors = e.data.errors || null;
        }
        this.savingStep = false;
      }
    },
    async loadSale(saleId, approach) {
      this.setLoading('sale');
      try {
        const sale = await this.fetchSale({ saleId, approach });
        await this.loadStep(sale.current_step);
      } catch (_) {
        this.$router.replace({ name: 'List Sales' });
      } finally {
        this.unsetLoading('sale');
      }
    },
    async loadStep(key) {
      this.$set(this.visibleSteps, key, true);
      this.setLoading(`step-${key}`);
      this.currentStep = key;
      const step = await this.fetchStep(key);
      this.unsetLoading(`step-${key}`);
      return step;
    },
    async showSidebar() {
      this.setLoading('sidebar');
      this.sidebarVisible = true;
      await this.fetchSaleDocuments(this.sale.id);
      this.unsetLoading('sidebar');
    },
  },
};
</script>
