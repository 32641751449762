var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "step--demands-and-needs" },
    [
      _c("pdf-viewer", {
        attrs: {
          base64: _vm.context.demands_and_needs.document,
          "signature-areas":
            _vm.context.demands_and_needs.state === "draft"
              ? _vm.context.demands_and_needs.signatures
              : [],
          signatures: _vm.signaturesComputed,
          "can-sign": ""
        },
        on: {
          signed: _vm.setSignature,
          "signature-removed": function($event) {
            return _vm.$delete(_vm.signatures, $event)
          }
        }
      }),
      _vm.hasError
        ? _c("div", { staticClass: "invalid-feedback d-block" }, [
            _vm._v(
              " Please ensure all signatures have been completed before proceeding "
            )
          ])
        : _vm._e(),
      !_vm.state.is_locked
        ? _c(
            "b-btn",
            {
              staticClass: "mt-5",
              attrs: { variant: "primary", disabled: _vm.savingStep },
              on: { click: _vm.saveDnd }
            },
            [
              _vm.savingStep
                ? _c("b-spinner", { staticClass: "mr-2", attrs: { small: "" } })
                : _vm._e(),
              _vm._v(" Submit ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }