<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card
          header="Create a new sale"
          v-bind="cardProps"
        >
          <compliance-form-wrapper
            v-if="saleCreateForm"
            :form-model="saleCreateFormComputed"
            :field-errors.sync="fieldErrors"
            @submit="doCreateSale"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    fieldErrors: null,
  }),
  computed: {
    ...mapGetters('sale', ['saleCreateForm']),
    saleCreateFormComputed() {
      return {
        ...this.saleCreateForm,
        fields: this.saleCreateForm.fields.map((f) => ({
          ...f,
          display_type: 'create-sale-radio',
        })),
      };
    },
  },
  mounted() {
    this.fetchSaleCreateForm();
  },
  methods: {
    ...mapActions('sale', ['createSale', 'fetchSaleCreateForm']),
    async doCreateSale(data) {
      try {
        const sale = await this.createSale(data);
        this.$router.push({
          name: 'View Sale',
          params: { saleId: sale.id },
        });
      } catch (e) {
        if (e.data) {
          this.fieldErrors = e.data.errors || null;
        }
      }
    },
  },
};
</script>
